var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a-slide__sports"},[_vm._m(0),_c('div',{staticClass:"a-slide__sports__wrapper"},[_c('div',{staticClass:"a-slide__sports--packages"},[_c('ul',_vm._l((_vm.packages),function(item,i){return _c('li',{key:'spk-' + i,staticClass:"packageBox",class:{
              'selected': _vm.selectedPckg(item),
              'included': _vm.includedPckg(item)
            }},[_c('div',{staticClass:"packageBox-inner",on:{"click":function($event){return _vm.handlePackages(item)}}},[_c('img',{attrs:{"src":_vm.baseUrl + item.image,"alt":""}}),_c('div',{staticClass:"tooltip-placeholder"},[_c('button',{staticClass:"btn icon",class:'package-' + item.id,on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleTooltip', 'package', item.id)},"blur":function($event){return _vm.$emit('handleTooltip', null, null)}}},[_c('img',{attrs:{"src":require('@/assets/images/intro14/icons/icon-i.png')}})])]),(_vm.tooltip && _vm.tooltip === 'package-' + item.id)?_c('div',{staticClass:"tooltip-holder",class:{'up': (_vm.packages.length - 1) === i || (_vm.packages.length - 2) === i},domProps:{"innerHTML":_vm._s(item.tooltip)}}):_vm._e()])])}),0)]),_c('div',{staticClass:"a-slide__sports--leagues"},[_c('div',{staticClass:"a-slide__sports--leagues-left"},[_c('div',{staticClass:"sportTypeSection"},[_c('h3',[_c('span',{domProps:{"textContent":_vm._s(_vm.sportTypes[0].name)}}),_c('div',{staticClass:"position-relative ml-3"},[_c('div',{staticClass:"tooltip-placeholder"},[_c('button',{staticClass:"btn icon",class:'sports-' + _vm.sportTypes[0].id,on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleTooltip', 'sports', _vm.sportTypes[0].id)},"blur":function($event){return _vm.$emit('handleTooltip', null, null)}}},[_c('img',{attrs:{"src":require('@/assets/images/intro14/icons/icon-i.png')}})])]),(_vm.tooltip && _vm.tooltip === 'sports-' + _vm.sportTypes[0].id)?_c('div',{staticClass:"tooltip-holder",class:_vm.sportTypes[0].tooltip_position,domProps:{"innerHTML":_vm._s(_vm.sportTypes[0].tooltip)}}):_vm._e()])]),_c('ul',_vm._l((_vm.football),function(item,i){return _c('li',{key:'fsb-'+ i},[_c('div',{staticClass:"sportBox",class:{
                  'selected': _vm.selectedSport(item),
                  'included': _vm.includedSport(item)
                },on:{"click":function($event){return _vm.handleSports(item)}}},[_c('img',{attrs:{"src":_vm.baseUrl + item.image,"alt":""}})])])}),0)])]),_c('div',{staticClass:"a-slide__sports--leagues-right"},[_c('div',{staticClass:"sportTypeSection"},[_c('h3',[_c('span',{domProps:{"textContent":_vm._s(_vm.sportTypes[1].name)}}),_c('div',{staticClass:"position-relative ml-3"},[_c('div',{staticClass:"tooltip-placeholder"},[_c('button',{staticClass:"btn icon",class:'sports-' + _vm.sportTypes[1].id,on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleTooltip', 'sports', _vm.sportTypes[1].id)},"blur":function($event){return _vm.$emit('handleTooltip', null, null)}}},[_c('img',{attrs:{"src":require('@/assets/images/intro14/icons/icon-i.png')}})])]),(_vm.tooltip && _vm.tooltip === 'sports-' + _vm.sportTypes[1].id)?_c('div',{staticClass:"tooltip-holder",class:_vm.sportTypes[1].tooltip_position,domProps:{"innerHTML":_vm._s(_vm.sportTypes[1].tooltip)}}):_vm._e()])]),_c('ul',_vm._l((_vm.basketballHockey),function(item,i){return _c('li',{key:'fsb-'+ i},[_c('div',{staticClass:"sportBox",class:{
                  'selected': _vm.selectedSport(item),
                  'included': _vm.includedSport(item)
                },on:{"click":function($event){return _vm.handleSports(item)}}},[_c('img',{attrs:{"src":_vm.baseUrl + item.image,"alt":""}})])])}),0)]),_c('div',{staticClass:"sportTypeSection"},[_c('h3',[_c('span',{domProps:{"textContent":_vm._s(_vm.sportTypes[2].name)}}),_c('div',{staticClass:"position-relative ml-3"},[_c('div',{staticClass:"tooltip-placeholder"},[_c('button',{staticClass:"btn icon",class:'sports-' + _vm.sportTypes[2].id,on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleTooltip', 'sports', _vm.sportTypes[2].id)},"blur":function($event){return _vm.$emit('handleTooltip', null, null)}}},[_c('img',{attrs:{"src":require('@/assets/images/intro14/icons/icon-i.png')}})])]),(_vm.tooltip && _vm.tooltip === 'sports-' + _vm.sportTypes[2].id)?_c('div',{staticClass:"tooltip-holder",class:_vm.sportTypes[2].tooltip_position,domProps:{"innerHTML":_vm._s(_vm.sportTypes[2].tooltip)}}):_vm._e()])]),_c('ul',_vm._l((_vm.other),function(item,i){return _c('li',{key:'fsb-'+ i},[_c('div',{staticClass:"sportBox",class:{
                  'selected': _vm.selectedSport(item),
                  'included': _vm.includedSport(item)
                },on:{"click":function($event){return _vm.handleSports(item)}}},[_c('img',{attrs:{"src":_vm.baseUrl + item.image,"alt":""}})])])}),0)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a-slide__sports__top-title"},[_c('h1',[_vm._v("Sportangebot")])])
}]

export { render, staticRenderFns }